import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import ActivateGiftCardDialog from "./ActivateGiftCardDialog";
import AddGiftCardDialog from "./AddInactiveGiftCardDialog";


const GiftCardMgmt: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [activateDialogOpen, setActivateDialogOpen] = useState(false);

    // Open & Close Dialog
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };

    // Open & Close Handlers
    const handleOpenActivateDialog = () => setActivateDialogOpen(true);
    const handleCloseActivateDialog = () => setActivateDialogOpen(false);


    return (
        <>
            <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center">
                <Grid item xs={4}>
                    <Button variant="contained" color="primary" onClick={handleOpen}>
                        Create Gift Card
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="contained" color="primary" onClick={handleOpenActivateDialog}>
                        Activate Gift Card
                    </Button>
                </Grid>
            </Grid>

            {/* Gift Card Management Dialog */}
            <AddGiftCardDialog open={open} onClose={handleClose} />
            <ActivateGiftCardDialog open={activateDialogOpen} onClose={handleCloseActivateDialog} />
        </>
    );
};

export default GiftCardMgmt;
