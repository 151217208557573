import { DeleteForeverTwoTone } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { createBulkGiftCards } from "../../../api/admin";
import { useAuth } from "../../../contexts/AuthContext";
import { GiftCardInput } from "../../../types/api";
import { logDebug } from "../../../utils/logger";
import { showErrorToast, showSuccessToast, showWarningToast } from "../../../utils/toastUtils";

interface GiftCard {
    internalId: string; //internal ID for the datagrid
    id: string;         //ID sent to the backend
    hash: string;
    dlcId: string;
    isValid?: boolean; // Field to track validation
}

// Helper function to generate unique internal IDs
const generateInternalId = () => crypto.randomUUID();

// Helper function to calculate the Mod10 checksum
const isValidChecksum = (segment: string): boolean => {
    let sum = 0;
    for (let i = 0; i < segment.length - 1; i++) {
        sum += segment.charCodeAt(i);
    }
    const missingChar = (10 - (sum % 10)) % 10;
    return segment.charCodeAt(segment.length - 1) === missingChar + 48; // 48 for '0' in ASCII
};

// Validate ID Format
const validateSegments = (formattedKey: string): boolean => {
    const segments = formattedKey.split("-");
    if (segments.length !== 5) {
        logDebug("Too many segments.");
        return false;
    }
    for (let i = 0; i < segments.length; i++) {
        const segment = segments[i];
        //Case 1: segment is not yet 5 characters:
        if (segment.length < 5) {
            //short circuit - this segment is not yet complete. no errors yet.
            return false;
        }
        //Case 2: segment is 5 characters long:
        else if (segment.length === 5 && !isValidChecksum(segment)) {
            logDebug(`Segment ${i + 1} is invalid.`);
            return false;
        }

        else if (segment.length > 5) {
            logDebug(`Segment ${i + 1} is too long.`);
            return false;
        }
    }
    return true;
};

interface AddGiftCardDialogProps {
    open: boolean;
    onClose: () => void;
}

const AddGiftCardDialog: React.FC<AddGiftCardDialogProps> = ({ open, onClose }) => {

    const [giftCards, setGiftCards] = useState<GiftCard[]>([]);
    const { idToken: token } = useAuth();

    // Handle Paste Event (Excel Copy-Paste)
    const handlePaste = (event: ClipboardEvent) => {
        event.preventDefault();
        const clipboardData = event.clipboardData?.getData("text");
        if (!clipboardData) return;

        const rows = clipboardData
            .split("\n")
            .map((line) => line.split("\t")) // Excel separates columns by tabs
            .filter((cols) => cols.length > 1) // Ensure there's data
            .map((cols) => ({
                internalId: generateInternalId(),
                id: cols[0] || "",
                hash: cols[1] || "",
                dlcId: cols[2] || "dlc0002",
                isValid: validateSegments(cols[0] || ""),
            }));

        setGiftCards((prev) => [...prev, ...rows]);
    };

    const handleAddRow = () => {
        setGiftCards((prev) => [
            ...prev,
            {
                internalId: generateInternalId(),
                id: "",
                hash: "",
                dlcId: "",
                isValid: false,
            },
        ]);
    };
    // Attach Paste Event Listener
    useEffect(() => {
        document.addEventListener("paste", handlePaste);
        return () => document.removeEventListener("paste", handlePaste);
    }, []);

    const handleClose = () => {
        setGiftCards([]); // Clear data on close
        onClose();
    };

    // Handle Cell Edit (Validate ID Format)
    const handleProcessRowUpdate = (updatedRow: GiftCard) => {
        updatedRow.isValid = validateSegments(updatedRow.id);
        setGiftCards((prev) =>
            prev.map((row) => (row.internalId === updatedRow.internalId ? updatedRow : row))
        );
        return updatedRow;
    };

    // Submit Valid Gift Cards
    const handleAddGiftCards = useCallback(async () => {
        try{
            const validGiftCards = giftCards.filter((gc) => gc.isValid);
            if (validGiftCards.length === 0) {
                alert("No valid gift cards to add.");
                return;
            }
            logDebug("Submitting Gift Cards:", validGiftCards);

            //input gift cards
            const inputGCs: GiftCardInput[] = giftCards.map((gc) => {
                return { dlcId: gc.dlcId.trim(), id: gc.id.trim(), hash: gc.hash.trim() };
            })

            const defaultDlcId = inputGCs[0].dlcId;

            // try{
            const response = await createBulkGiftCards(token!, defaultDlcId, inputGCs);
            if (!response.success) {
                showErrorToast(`Failed to add gift cards: ${response.message}`);
            }
            else{
                showSuccessToast(`${response.message}`);
                for(const gc of response.data.giftcards){
                    showWarningToast(`Failed to add: ${gc.id}`);
                }
            }
            // }
            // catch(e: any){
            //     showErrorToast(`${e.message}`);
            // }
            

            // giftCards.map(async (gc) => {
            //     if (gc.isValid && token) {
            //         // API Call can be placed here instead
            //         try{
            //             const response = await createGiftCard(token, gc.dlcId.trim(), gc.id.trim(), gc.hash.trim());
            //             if (!response.success) {
            //                 showErrorToast(`Failed to add gift card: ${response.message}`);
            //             }
            //             else{
            //                 showSuccessToast(`Successfully added gift card: ${gc.id}`);
            //             }
            //         }
            //         catch(e: any){
            //             logDebug('Error adding gift cards', e);
            //             showErrorToast(`${e.message}`);
            //         }
            //     }
            // })
        }
        catch(e){
            logDebug('Error adding gift cards', e);
            showErrorToast('Failed to add gift cards');
        }
        finally{
            handleClose();
        }
    }, [giftCards, token]);

    const handleDelete = (row: GiftCard) => {
        setGiftCards((prev) => prev.filter((gc) => gc.internalId !== row.internalId));
    }

    // Define Columns with Validation Highlighting
    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "Gift Card ID",
            flex: 2,
            editable: true,
            cellClassName: (params) => (!params.row.isValid ? "invalid-cell" : ""),
        },
        {
            field: "hash",
            headerName: "Gift Card Hash",
            flex: 1,
            editable: true,
        },
        {
            field: "dlcId",
            headerName: "DLC",
            flex: 1,
            editable: true,
        },
        {
            field: 'delete',
            headerName: 'Del',
            width: 32,
            renderCell: (params: any) => {
                return (
                  <Box>
                    
                    <IconButton>
                      <DeleteForeverTwoTone
                        onClick={() => handleDelete(params.row)}
                      />
                    </IconButton>
                  </Box>
                );
              }
        }
    ];

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Manage Gift Cards</DialogTitle>
            <DialogContent style={{ height: 400 }} onDoubleClick={handleAddRow}>
                <DataGrid
                    rows={giftCards}
                    columns={columns}
                    editMode="row"
                    processRowUpdate={handleProcessRowUpdate}
                    getRowId={(row) => row.internalId}
                    sx={{
                        "& .invalid-cell": {
                            backgroundColor: "#FFCDD2", // Light red for invalid cells
                            color: "#B71C1C", // Dark red text
                        },
                    }}
                    slots={{
                        noRowsOverlay: () => (
                        //   <div onDoubleClick={handleAddRow}>
                            <Stack height="100%" alignItems="center" justifyContent="center" >
                            Double-Click to add a new row
                          </Stack>
                        //   </div>
                          
                        ),
                        noResultsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            Local filter returns no result
                          </Stack>
                        ),
                      }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">Cancel</Button>
                <Button onClick={handleAddGiftCards} color="primary" variant="contained">
                    Add Gift Cards
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddGiftCardDialog;