import { APIResponse, AdminPortalData, GiftCard, GiftCardInput } from '../types/api';

const API_URL = process.env.REACT_APP_CUSTOM_API_URL as string;

export const tryAdminLogin = async (token: string): Promise<APIResponse<{ isAdmin: boolean }>> => {
  const response = await fetch(`${API_URL}/admin/admin-login`, {
    method: 'POST',

    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Failed to login: ${errorData.error}`);
  }

  const resp = await response.json();
  return {
    success: true,
    data: { isAdmin: true },
    message: resp.message,
  }
};

/**
 * Create an Inactive Gift Card in the database
 * 
 * @param token the user auth token
 * @param dlcId the DLC ID to associate with the gift card
 * @param secretKey the secret key of the gift card that a user can redeem with
 * @param hash the barcode hash of the gift card that can be activated
 * @returns a new, inactive gift card object associated with the given DLC ID
 */
export const createGiftCard = async (
  token: string,
  dlcId: string,
  secretKey: string,
  hash: string
): Promise<APIResponse<{ giftcard: GiftCard }>> => {
  const response = await fetch(`${API_URL}/admin/add-giftcard`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ dlcId, gc_id: secretKey, gc_hash: hash }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Failed to create gift card: ${errorData.error}`);
  }

  const data = await response.json();
  return {
    success: true,
    data: { giftcard: data.giftCard },
  }
}

export const createBulkGiftCards = async (
  token: string,
  defaultDlcId: string,
  giftCards: GiftCardInput[],
  stripeProductId?: string,
): Promise<APIResponse<{ giftcards: GiftCard[] }>> => {

    const response = await fetch(`${API_URL}/admin/add-bulk-giftcards`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ dlcId: defaultDlcId, giftCards, stripeProductId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Failed to create gift cards: ${errorData.error}`);
    }

    const data = await response.json();
    return {
      success: true,
      data: { giftcards: data.failedGiftCards },
      message: data.message,
    }
  }

/**
 * Activate a Gift Card in the database by its barcode
 * 
 * @param token the user auth token
 * @param barcode the barcode of the gift card to activate
 * @returns a gift card object that has been activated
 */
export const activateGiftCard = async (
  token: string,
  barcode: string,
): Promise<APIResponse<{ giftcard: GiftCard }>> => {
  const response = await fetch(`${API_URL}/admin/activate-giftcard`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ hash: barcode }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Failed to activate gift card: ${errorData.error}`);
  }

  const data = await response.json();
  return {
    success: true,
    data: { giftcard: data.giftCard },
  }
}

/**
 * Get all gift cards in the database
 * @param token the user auth token
 * @param pageSize the number of gift cards to return per query
 * @param continuationToken (optional) the token to continue querying from
 * @returns a list of gift cards {@link GiftCard} and a continuation token
 */
export const getGiftCards = async (
  token: string,
  pageSize: number,
  continuationToken?: string,
): Promise<APIResponse<{ giftcards: GiftCard[] }>> => {
  const response = await fetch(`${API_URL}/admin/get-gift-cards`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ pageSize, continuationToken }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Failed to get gift cards: ${errorData.error}`);
  }

  const data = await response.json();
  return {
    success: true,
    data: { giftcards: data.giftCards },
    continuation_token: data.continuationToken,
  }
}

export const deleteInactiveGC = async (
  token: string,
  gc_id: string,
): Promise<APIResponse<{ giftcard?: GiftCard }>> => {
  const response = await fetch(`${API_URL}/admin/delete-inactive-giftcard`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ gc_id }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Failed to delete gift card: ${errorData.error}`);
  }

  const data = await response.json();
  return {
    success: true,
    data: { giftcard: data.giftCard },
    message: data.message ?? "",
  }
};

export const getAdminPortalData = async (
  token: string,
  pageSize: number,
  continuationToken?: string,
): Promise<APIResponse<AdminPortalData>> => {
  const response = await fetch(`${API_URL}/admin/admin-portal`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ pageSize, continuationToken }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Failed to get admin data: ${errorData.error}`);
  }

  const data = await response.json();
  return {
    success: true,
    data: data,
  }
};

export const deleteUser = async (
  token: string,
  userId: string,
): Promise<APIResponse<{ message: string }>> => {
  const response = await fetch(`${API_URL}/admin/delete-user`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ userId }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Failed to delete user: ${errorData.error}`);
  }

  const data = await response.json();
  return {
    success: true,
    data: data,
  }
};
