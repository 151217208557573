import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { activateGiftCard } from "../../../api/admin";
import { useAuth } from "../../../contexts/AuthContext";
import { logDebug } from "../../../utils/logger";
import { showErrorToast, showSuccessToast } from "../../../utils/toastUtils";

interface ActivateGiftCardDialogProps {
  open: boolean;
  onClose: () => void;
  onActivate?: (giftCardId: string) => void;
}

const ActivateGiftCardDialog: React.FC<ActivateGiftCardDialogProps> = ({ open, onClose }) => {
  const [giftCardId, setGiftCardId] = useState("");
  const { idToken: token } = useAuth();

  const handleActivate = useCallback(async () => {
    if (giftCardId.trim() === "") {
      alert("Please enter a Gift Card ID.");
      return;
    }
    logDebug(`Activating Gift Card: ${giftCardId}`);
    // API Call can be placed here instead
    if (token) {
      const msg = await activateGiftCard(token, giftCardId);
      logDebug(msg);
      showSuccessToast(`Gift card ${msg.data.giftcard.hash} activated successfully`);
    }
    else {
      showErrorToast("Failed to activate gift card. ID not valid");
    }
    handleClose();
  }, [giftCardId, token, onClose]);

  const handlePaste = (event: ClipboardEvent) => {
    event.preventDefault();
    const text = event.clipboardData?.getData('text');
    if (text) {
      setGiftCardId(text.trim());
    }
  }

  const handleClose = () => {
    setGiftCardId("");
    onClose();
  }

  // Attach Paste Event Listener
  useEffect(() => {
    document.addEventListener("paste", handlePaste);
    return () => document.removeEventListener("paste", handlePaste);
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Activate a Gift Card</DialogTitle>
      <DialogContent>
        <TextField
          label="Gift Card Barcode"
          fullWidth
          variant="outlined"
          value={giftCardId}
          onChange={(e) => setGiftCardId(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">Cancel</Button>
        <Button onClick={handleActivate} color="primary" variant="contained">
          Activate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActivateGiftCardDialog;